<template>
    <div align="center" v-if="is_mounted">
        <div class="text-h4">Carica i Simpli</div>
        <br>
        <strong>Scarica i Simpli da allegare alla quietanza selezionata</strong>
        <br>
        <hr>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row justify-center">
            <div class="col-12 col-md-5 q-pr-md" align="left">
                <h5 class="q-mt-sm q-mb-sm">Dati generali pratica</h5>
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Cliente:</q-item-label>
                            <q-item-label caption>{{dati_pratica.nominativo.toUpperCase()}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della pratica:</q-item-label>
                            <q-item-label caption>{{ dati_pratica.stato_pratica }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero interno:</q-item-label>
                            <q-item-label caption>{{dati_pratica.numero_pratica}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Numero pratica:</q-item-label>
                            <q-item-label caption>{{dati_pratica.numero}}</q-item-label>
                        </q-item-section>
                    </q-item>
                <!--    <q-item>
                        <q-item-section>
                            <q-item-label>Compagnia:</q-item-label>
                            <q-item-label caption>{{dati_pratica.compagnia}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{dati_pratica.prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Decorrenza:</q-item-label>
                            <q-item-label caption>{{dati_pratica.decorrenza}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Scadenza:</q-item-label>
                            <q-item-label caption>{{dati_pratica.scadenza}}</q-item-label>
                        </q-item-section>
                    </q-item> //-->
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unità operativa richiedente:</q-item-label>
                            <q-item-label caption>{{dati_pratica.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Persona operativa:</q-item-label>
                            <q-item-label caption>{{dati_pratica.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <br>
            </div>

            <div class="col-12 col-md-5">
                <q-input
                    label="Note su caricamento file"
                    v-model="note_upload"
                    filled
                    type="textarea"
                />
                <br><br>
            </div>

            <div class="col-12 col-md-10" align="center">
                <q-uploader
                    ref="UploadObject"
                    url="https://localhost:1000"
                    color="teal"
                    label="Trascina QUI i PDF dei SIMPLI"
                    multiple
                    hide-upload-btn
                    name="upload_simpli"
                    :filter="checkFileType"
                    @rejected="onRejected"
                    @uploading="onUploading"
                    style="max-width: 800px; width: 100%; min-height: 300px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"

                />
            </div>

        </div>
        <br><hr>
            <div class="row" align="center">
                <div class="col-12 col-md-6" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked"
                    />
                </div>
                <div class="col-12 col-md-6" align="center">
                    <QQButton label="CARICA SIMPLI" color="blue-grey" icon="mdi-arrow-right-bold" size="md" :disable="pulsante_disattivato"
                        @click.native="onUploading"
                    />
                </div>
            </div>
        <br><br><br><br>
    </div>
</template>

<script>
    import axios from 'axios';
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    import { mapFields } from "vuex-map-fields";
    import { mapActions } from "vuex";
    //import { exportFile } from 'quasar';

    export default {
        name: "UploadSimpli",
        data() {
            return {
                dati_pratica: {},
                is_mounted: false,
                is_loading: true,
                note_upload: "",
                pulsante_disattivato: false
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields("gestioneSimpli", {
                idpratica: "idpratica",
                idcontratto: "idcontratto",
                idquietanza: "idquietanza",
                guid_pratica: "guid_pratica",
                // is_loading: "is_loading"
            })
        },
        methods: {
            ...mapActions({
                fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
                fetchUploadUrlSimpli: "gestioneDocumenti/fetchUploadUrlSimpli",
                registra_simplo_su_pratica: "gestioneSimpli/registra_simplo_su_pratica"
            }),
            checkFileType(files) {
                let status = (files.filter(file => file.type === 'application/pdf').length === 1) ||
                                (files.filter(file => file.type === 'application/vnd.fdf').length === 1);
                if (status) {
                    return files.filter(file => file.type === files[0].type);
                }

                return [];
            },
            onRejected () {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il tipo di documento allegato può essere solo nel formato PDF,FDF. Gli altri formati non sono accettati per questioni di sicurezza.'
                });
            },
            async onUploading() {
                let files = this.$refs.UploadObject.files;

                if (files.length < 1) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message:  "Nessun Simplo allegato. Controlla prima di continuare",
                    });

                    return;
                }

                this.disattivaPulsanti();
                this.is_loading = false;

                console.log("files:",files);

                for( var i = 0; i < files.length; i++ ) {
                    let file = files[i];
                    let formData = new FormData();
                    formData.append('files[' + i + ']', file);

                    // recupera url da S3
                    let url = await this.fetchUploadUrlSimpli({
                        guid_pratica:this.guid_pratica,
                        Filename:file.name,
                        IDQuietanza: this.idquietanza
                    });

                    console.log("url:",url)

                    if (url.error !== "") {
                        this.$q.dialog({
                            title: 'Attenzione',
                            message: url.error
                        });

                        return;
                    }

                    var have_error = false;

                    await axios.put(
                        url.url,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(function(){
                            console.log('SUCCESS!!');
                            have_error = have_error || false;
                            alert("I simpli allegati sono stati registrati senza errori");

                        }).catch(function(){
                            console.log('FAILURE!!');
                            have_error = have_error || true;
                            alert("Errore durante il caricamento dei simpli");
                        });
                }

                this.is_loading = true;

                if (!have_error) {
                    // Modifica lo stato del contratto
                    await this.registra_simplo_su_pratica({
                        guid_pratica: this.guid_pratica,
                        id_quietanza: this.idquietanza,
                        note: this.note_upload
                    });

                    this.$router.push({name : "Simpli.SimpliDaCaricare"});
                }
            },
            onIndietroClicked() {
                this.$router.push({name : "Simpli.SimpliDaCaricare"});
            },
            attivaPulsanti() {
                this.pulsante_disattivato = false;
            },
            disattivaPulsanti() {
                this.pulsante_disattivato = true;
                 setTimeout(()=>{
                    this.attivaPulsanti()
                }, 25000);
            },
        },
        async mounted() {
            this.is_mounted = false;
            this.is_loading = true;

            this.dati_pratica = await this.fetchDatiPratica({
                numero_pratica: this.guid_pratica,
                token: ""
            });

            console.log("dati pratica:",this.dati_pratica);

            this.is_mounted = true;
        }
    }
</script>
